import * as React from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";

// markup
const NotFoundPage = () => {
  return (
    <main>
      <Helmet>
        <title>Not found</title>
      </Helmet>
      <section className="container">
        <h1 className="py-7">Page not found</h1>
        <p className="py-14">
          Sorry{" "}
          <span role="img" aria-label="Pensive emoji">
            😔
          </span>{" "}
          we couldn’t find what you were looking for.
          <br />
          <br />
          <Link to="/">Go home →</Link>
        </p>
      </section>
    </main>
  );
};

export default NotFoundPage;
